<template>
  <static-fullscreen-card>
    <template v-slot:title> ({{ data.id }}) {{ data.city }} {{ data.address }} [{{ statusCurrent.text }}] </template>
    <template v-slot:actions>
      <a-btn-status :items="statusItems" @click="clickStatus($event)" :disabled="loading" />

      <a-btn-edit v-if="getAccess('consider.edit', id)" @click="showEditDialog = true" :disabled="loading" />
    </template>
    <portal to="v-main">
      <edit-dialog v-model="showEditDialog" :id="id" :api="url"></edit-dialog>
    </portal>
    <v-row>
      <v-col cols="12" sm="6">
        <material-card color="secondary">
          <template v-slot:heading>
            <div class="px-3 py-1">фото объекта</div>
          </template>
          <a-view-images :value="data.photos" :style="{ height: blockHeight + 'px' }" />
        </material-card>
      </v-col>
      <v-col cols="12" sm="6">
        <material-card color="secondary">
          <template v-slot:heading>
            <div class="px-3 py-1">основная информация</div>
          </template>

          <a-form-view
            :value="data"
            :model="[model[0], model[3], model[4], model[5], { name: 'price_m2', title: 'Стоимость кв.м', type: 'string' }, model[7], model[8], model[11], model[12]]"
            :config="{ dense: true }"
            class="block_master"
          />
        </material-card>
      </v-col>
      <v-col cols="12">
        <material-card color="third">
          <template v-slot:heading>
            <v-tabs v-model="tab" background-color="transparent" slider-color="white">
              <v-tab v-for="(tab, i) in tabs" :key="i" class="mr-3">{{ tab.title }}</v-tab>
            </v-tabs>
          </template>
          <v-tabs-items v-model="tab" class="transparent" style="min-height: 200px" touchless>
            <v-tab-item>
              <v-card-text style="height: 300px; overflow: auto">
                <a-view-files :value="data.docs" />
              </v-card-text>
            </v-tab-item>
          </v-tabs-items>
        </material-card>
      </v-col>
    </v-row>
    <div v-if="loading" class="loading-block">
      <div class="loader"></div>
    </div>
    <confirm-dialog ref="confirmDialog"></confirm-dialog>
  </static-fullscreen-card>
</template>

<script>
import { statusChange, autoHeightBlock, getAccess } from "../../components/mixings";
export default {
  mixins: [statusChange, autoHeightBlock, getAccess],
  components: {
    editDialog: () => import("./../dialogs/considerDialog"),
  },
  data() {
    return {
      id: 0,
      blockHeight: 250,
      showEditDialog: false,
      loading: false,
      title: "",
      data: {},
      model: this.$store.getters["config/get"].models.considers.form,
      url: "/mechti/objects/under_consideration",
      tab: 0,
      tabs: [{ title: "Документация" }],
    };
  },
  created() {
    this.$root.title = "Объект для рассмотрения";
    this.id = Number(this.$route.params.id);
    this.fitchData();
  },
  computed: {},
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        this.fitchData();
      }
    },
  },
  methods: {
    async fitchData() {
      const d = await this.$axios.get(this.url + "/" + this.id);
      if (d.data && d.data.data) {
        this.data = d.data.data;
        this.data.price_m2 = "";
        if (this.data.ap_area && this.data.ap_price_buy) {
          this.data.price_m2 = (this.data.ap_price_buy / this.data.ap_area).toFixed(2);
        }
      }
    },
  },
};
</script>
